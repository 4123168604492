import React from 'react';


function Weather() {
  return (
    <div></div>
  );
}

export default Weather;
